import React, { useEffect, useState } from 'react';
// Change useHistory to useNavigate
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import RestApi from '../../Apis/RestApi';
import { toast } from 'react-toastify';

const ResetPasswordRedirect = () => {
  const [message, setMessage] = useState('');
  // Change useHistory to useNavigate
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    const resetPassword = async () => {
      try {
        const response = await RestApi.resetPasswordToken(token);
        toast.success(response.data.message);
        if (response.data.redirectUrl) {
          navigate(response.data.redirectUrl,{
            state:{user_id:response.data.data.user_id}
          }); 
        }
      } catch (error) {
        toast.error("Failed", error.response?.data?.message);
      }
    };

    if (token) {
      resetPassword();
    }
  }, [navigate]);

  return (
    <div className='h-screen w-screen bg-slate-900 flex justify-center items-center'>
     <div className=' '>
     <h1 className='text-white text-7xl mb-8' >Reset Password</h1>
     <p className='text-white text-2xl'>{message?message:"Check your mail for password reset"}</p>
     </div>
    </div>
  );
};

export default ResetPasswordRedirect;
