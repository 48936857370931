import axios from "axios";

export default axios.create({
  // baseURL: "http://qr.dbaego.com:4005",
  baseURL: "https://smart-qrcodes.com/api",
  // baseURL: "http://localhost:4005/api",
  headers: {
    "Content-type": "application/json",
  },
});
