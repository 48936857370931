import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logoImage from '../Assets/WebDimension.png';
import logo from '../Assets/Logo_text.png';

function Sidebar({ isMobile, closeSidebar, setIsSidebarOpen }) {
  const navigate = useNavigate();
  const location = useLocation();
  
  const handleNavigation = (path) => {
    // Navigate relative to /home
    navigate(`/home${path}`);
    if (isMobile) {
      closeSidebar();
    }
  };

  const isActive = (path) => {
    // Check if the current path matches (including /home prefix)
    return location.pathname === `/home${path}` || 
           (path === '' && location.pathname === '/home');
  };

  return (
    <div className={`${isMobile ? "w-screen" : ""} bg-[#1e293b] p-4 flex flex-col justify-between h-screen relative`}>
      {isMobile && (
        <button 
          className="absolute top-2 right-2 text-white text-2xl"
          onClick={() => setIsSidebarOpen(false)}
        >
          ×
        </button>
      )}
      <div className={`${!isMobile ? "min-w-48" : ""}`}>
        {/* Changed to use navigate instead of anchor tag for consistent routing */}
        <button onClick={() => handleNavigation('')} className="block">
          <img src={logo} className="h-12 w-40 my-4 mb-12" alt="Logo" />
        </button>
        <nav>
          <ul className="space-y-2">
            <li className={isActive('') ? 'bg-[#334155] text-white font-medium p-2 rounded-md' : 'p-2 text-gray-300'}>
              <button onClick={() => handleNavigation('')} className="w-full text-left">
                QR Codes
              </button>
            </li>
            <li className={isActive('/smartlink') ? 'bg-[#334155] text-white font-medium p-2 rounded-md' : 'p-2 text-gray-300'}>
              <button onClick={() => handleNavigation('/smartlink')} className="w-full text-left">
                Smart Links
              </button>
            </li>
            <li className={isActive('/aicodes') ? 'bg-[#334155] text-white p-2 rounded-md' : 'p-2 text-gray-300'}>
              <button onClick={() => handleNavigation('/aicodes')} className="w-full text-left">
                AI Codes
              </button>
            </li>
            <li className={isActive('/settings') ? 'bg-[#334155] text-white p-2 rounded-md' : 'p-2 text-gray-300'}>
              <button onClick={() => handleNavigation('/settings')} className="w-full text-left">
                Settings
              </button>
            </li>
          </ul>
        </nav>
      </div>
      <div>
        <button 
          className="w-full bg-[#9BEC00] text-black font-bold py-2 px-4 rounded mb-12" 
          onClick={() => navigate("/premium")}
        >
          Get Full Access
        </button>
        <img 
          src={logoImage} 
          alt="Powered By Web Dimension" 
          className='h-[70px] w-full max-w-[140px] mb-4'
        />
        <p className='text-[#9BEC00] text-sm'>@ A Navneet Enterprise Company.</p>
      </div>
    </div>
  );
}

export default Sidebar;