import React from 'react'

function AiCodes() {
  return (
    <div className='flex h-full justify-center items-center'>
    <div className='rounded-lg'>
    <p className="relative inline-block px-16 py-8 font-bold text-white rounded-lg overflow-hidden bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 hover:bg-gradient-to-l shadow-lg group">
  <span className="absolute inset-0 bg-[length:400%_400%] bg-gradient-to-r from-purple-400 via-pink-400 to-yellow-400 opacity-75 transition-opacity duration-500 ease-out animate-glitter"></span>
  <span className="relative z-10 size-5">COMING SOON</span>
  </p>    
    </div>    
    </div>
  )
}

export default AiCodes