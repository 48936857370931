import React, { useState } from 'react';
import { HiOutlineLogout } from "react-icons/hi";
import { useNavigate } from 'react-router-dom';
import ChangePassword from '../../components/ChangePassword';
import { useAuth } from '../../context/AuthContext';
import { Mail, MapPin, Phone } from 'lucide-react';

function Settings() {
    const username = localStorage.getItem("username");
    const email = localStorage.getItem("email");
    const navigate = useNavigate();
    const [changePassClicked, setChangePassClicked] = useState(false);
    const { logout } = useAuth();

    const logoutHandler = () => {
        localStorage.removeItem("email");
        localStorage.removeItem("_id");
        localStorage.removeItem("type");
        logout();
        navigate("/login");
    };

    const ChangePasswordHandler = () => {
        setChangePassClicked(true);
    };

    return (
        <div className="flex h-screen bg-[#0f172a] text-white overflow-hidden">
            <div className="flex-1 flex flex-col overflow-hidden">
                {/* Fixed Header */}
                <div className="flex-shrink-0 p-4 sm:p-6 border-b border-gray-700">
                    <div className="flex justify-between items-center">
                        <h1 className="text-2xl sm:text-3xl font-semibold">My Account</h1>
                       
                    </div>
                </div>

                {/* Scrollable Content */}
                <div className="flex-1 overflow-y-auto p-4 sm:p-6">
                    {!changePassClicked ? (
                        <>
                            {/* My Details */}
                            <div className="bg-white shadow rounded-lg p-4 sm:p-6 mb-6">
                                <h2 className="text-xl font-semibold mb-4 text-black">My Details</h2>
                                <div className="grid gap-3">
                                    <div className="flex">
                                        <h3 className="text-black font-medium">Name:</h3>
                                        <p className="text-black ml-2">{username.toUpperCase()}</p>
                                    </div>
                                    <div className="flex">
                                        <h3 className="text-black font-medium">Email:</h3>
                                        <p className="text-black ml-2">{email.toUpperCase()}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center space-x-2 mb-4">
                            <button className="bg-[#126f97] text-white py-2 px-4 rounded-lg text-sm sm:text-base"
                                onClick={ChangePasswordHandler}>
                                Change Password
                            </button>
                        </div>
                            {/* Billing */}
                            <h2 className="text-xl sm:text-2xl font-semibold mb-4">Billing</h2>
                            <div className="bg-white shadow rounded-lg p-4 sm:p-6 mb-6">
                                <h3 className="font-semibold text-lg mb-4 text-black">Plan details</h3>
                                <div className="overflow-x-auto">
                                    <table className="w-full text-left text-sm">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th className="px-4 py-2 text-gray-500">Current plan</th>
                                                <th className="px-4 py-2 text-gray-500">Due date</th>
                                                <th className="px-4 py-2 text-gray-500">Amount due</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="bg-white border-t">
                                                <td className="px-4 py-2 text-gray-700">Advanced (Trial)</td>
                                                <td className="px-4 py-2 text-gray-700">-</td>
                                                <td className="px-4 py-2 text-gray-700">-</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            {/* Usage Section */}
                            <h2 className="text-xl sm:text-2xl font-semibold mb-4">Usage</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                                {/* Users */}
                                <div className="bg-white shadow rounded-lg p-4 sm:p-6">
                                    <div className="flex items-center justify-between mb-2">
                                        <span className="font-medium text-gray-700">Users</span>
                                        <span className="text-sm text-gray-500">1/2</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div className="bg-blue-500 h-2.5 rounded-full" style={{ width: '50%' }}></div>
                                    </div>
                                    <a href="#" className="text-sm text-orange-500 mt-2 inline-block">Upgrade to add more users</a>
                                </div>

                                {/* Dynamic QR Codes */}
                                <div className="bg-white shadow rounded-lg p-4 sm:p-6">
                                    <div className="flex items-center justify-between mb-2">
                                        <span className="font-medium text-gray-700">Dynamic QR Codes</span>
                                        <span className="text-sm text-gray-500">2/50</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div className="bg-blue-500 h-2.5 rounded-full" style={{ width: '4%' }}></div>
                                    </div>
                                    <a href="#" className="text-sm text-orange-500 mt-2 inline-block">Upgrade to create more codes</a>
                                </div>
                            </div>

                            {/* Contact Us*/}
                            <div className="bg-white shadow rounded-lg p-4 sm:p-6 mb-8">
                            <h2 className="font-medium text-gray-700 mb-4">Contact Us</h2>
                            <div className="space-y-3">
                                <div className="flex items-start">
                                <MapPin className="w-5 h-5 text-orange-500 mr-2 mt-1 flex-shrink-0" />
                                <span className="text-sm text-gray-600">
                                    E-1007, Prateek laurel, Sector-120, Noida
                                </span>
                                </div>
                                <div className="flex items-center">
                                <Mail className="w-5 h-5 text-orange-500 mr-2 flex-shrink-0" />
                                <a href="mailto:support@smart-qrcodes.com" className="text-sm text-blue-600 hover:underline">
                                    support@smart-qrcodes.com
                                </a>
                                </div>
                                <div className="flex items-center">
                                <Phone className="w-5 h-5 text-orange-500 mr-2 flex-shrink-0" />
                                <a href="tel:+1234567890" className="text-sm text-blue-600 hover:underline">
                                   +91 8287104351
                                </a>
                                </div>
                            </div>
                            <div className="mt-4 pt-3 border-t border-gray-200">
                                <a href="#" className="text-sm text-orange-500 hover:underline">
                                View on map
                                </a>
                            </div>
                            </div>
                            {/* Logout Button */}
                            <button className="text-red-800 bg-slate-300 py-3 px-6 flex items-center rounded-lg"
                                onClick={logoutHandler}>
                                Logout <HiOutlineLogout className="ml-2" />
                            </button>
                        </>
                    ) : (
                        <ChangePassword />
                    )}
                </div>
            </div>
        </div>
    );
}

export default Settings;