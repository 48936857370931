import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, CartesianGrid } from 'recharts';
import { Card, Tabs } from 'flowbite-react';
import { HiOutlineGlobe } from 'react-icons/hi';
import { useParams } from 'react-router-dom';
import RestApi from '../../Apis/RestApi';

const QRDetailsPage = () => {
  const [scanHistory, setScanHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [activeView, setActiveView] = useState('day');
  
  const [customDateRange, setCustomDateRange] = useState({
    from: new Date(),
    to: new Date()
  });

  const { id: qrId } = useParams();

  // UTC to IST conversion helper
  const getISTDate = (utcDate) => {
    const date = new Date(utcDate);
    return new Date(date.getTime() + (5.5 * 60 * 60 * 1000));
  };

  // Helper to check if two dates are the same day in IST
  const isSameISTDay = (date1, date2) => {
    const d1 = getISTDate(date1);
    const d2 = getISTDate(date2);
    return d1.getDate() === d2.getDate() &&
           d1.getMonth() === d2.getMonth() &&
           d1.getFullYear() === d2.getFullYear();
  };

  const getTodayData = () => {
    const istNow = getISTDate(new Date());
    
    // Set to start of today in IST
    const istToday = new Date(istNow);
    istToday.setHours(0, 0, 0, 0);
  
    const hourRanges = Array(24).fill(0).map((_, index) => ({
      range: `${String(index).padStart(2, '0')}:00-${String((index + 1) % 24).padStart(2, '0')}:00`,
      count: 0
    }));
  
    scanHistory.forEach(scan => {
      const istScanTime = getISTDate(scan.timestamp);
      // Compare dates only (ignore time)
      if (istScanTime.getFullYear() === istToday.getFullYear() &&
          istScanTime.getMonth() === istToday.getMonth() &&
          istScanTime.getDate() === istToday.getDate()) {
        const hour = istScanTime.getHours();
        hourRanges[hour].count++;
      }
    });
  
    return hourRanges.map(item => ({
      time: item.range,
      scans: item.count
    }));
  };
  
  const getCurrentWeekData = () => {
    const istNow = getISTDate(new Date());
    
    // Calculate start of week (7 days ago) in IST
    const startOfWeek = new Date(istNow);
    startOfWeek.setDate(istNow.getDate() - 6);
    startOfWeek.setHours(0, 0, 0, 0);
    
    // Calculate end of week (today) in IST
    const endOfWeek = new Date(istNow);
    endOfWeek.setHours(23, 59, 59, 999);
  
  
    const days = Array(7).fill(0).map((_, index) => {
      const date = new Date(startOfWeek);
      date.setDate(startOfWeek.getDate() + index);
      return {
        date,
        day: date.toLocaleDateString('en-US', { weekday: 'short' }),
        count: 0
      };
    });
  
    scanHistory.forEach(scan => {
      const istScanTime = getISTDate(scan.timestamp);
      // Check if scan is within week range
      if (istScanTime >= startOfWeek && istScanTime <= endOfWeek) {
        const dayIndex = days.findIndex(dayData => 
          istScanTime.getFullYear() === dayData.date.getFullYear() &&
          istScanTime.getMonth() === dayData.date.getMonth() &&
          istScanTime.getDate() === dayData.date.getDate()
        );
        if (dayIndex !== -1) {
          days[dayIndex].count++;
        }
      }
    });
  
    return days.map(item => ({
      time: item.day,
      scans: item.count
    }));
  };
  
  // useEffect(() => {
  //   console.log('Chart data updated:', chartData);
  // }, [chartData]);
  
  // Add validation to ensure scan history is properly formatted
  // useEffect(() => {
  //   if (scanHistory.length > 0) {
  //     console.log('Validating scan history...');
  //     scanHistory.forEach((scan, index) => {
  //       if (!scan.timestamp) {
  //         console.error(`Scan at index ${index} missing timestamp:`, scan);
  //       }
  //       const timestamp = new Date(scan.timestamp);
  //       if (isNaN(timestamp.getTime())) {
  //         console.error(`Invalid timestamp at index ${index}:`, scan.timestamp);
  //       }
  //     });
      
  //     console.log('Setting initial chart data...');
  //     setChartData(getTodayData());
  //   }
  // }, [scanHistory]);

  // Get Current Month Data
  const getCurrentMonthData = () => {
    const istNow = getISTDate(new Date());
    const currentYear = istNow.getFullYear();
    const currentMonth = istNow.getMonth();
    
    // Get number of days in current month
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  
    // Initialize array for each day in month
    const days = Array(daysInMonth).fill(0).map((_, index) => ({
      day: index + 1,
      count: 0
    }));
  
    scanHistory.forEach(scan => {
      const istScanTime = getISTDate(scan.timestamp);
      if (istScanTime.getFullYear() === currentYear && 
          istScanTime.getMonth() === currentMonth) {
        const dayOfMonth = istScanTime.getDate();
        days[dayOfMonth - 1].count++;
      }
    });
  
    return days.map(item => ({
      time: item.day.toString(),
      scans: item.count
    }));
  };
  

  // Get Current Year Data
  const getCurrentYearData = () => {
    const today = getISTDate(new Date());
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    
    // Initialize array for each month
    const monthData = months.map(month => ({
      month,
      count: 0
    }));

    // Count scans per month
    scanHistory.forEach(scan => {
      const istDate = getISTDate(scan.timestamp);
      if (istDate.getFullYear() === today.getFullYear()) {
        monthData[istDate.getMonth()].count++;
      }
    });

    return monthData.map(item => ({
      time: item.month,
      scans: item.count
    }));
  };

  // Get Custom Date Range Data
  const getCustomData = () => {
    const startDate = getISTDate(customDateRange.from);
    const endDate = getISTDate(customDateRange.to);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    const days = [];
    for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
      days.push({
        date: new Date(d),
        count: 0
      });
    }

    scanHistory.forEach(scan => {
      const istScanDate = getISTDate(scan.timestamp);
      const dayEntry = days.find(day => 
        isSameISTDay(istScanDate, day.date)
      );
      if (dayEntry) {
        dayEntry.count++;
      }
    });

    return days.map(item => ({
      time: item.date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }),
      scans: item.count
    }));
  };

  const tabIndexToView = ['day', 'week', 'month', 'year', 'custom'];

  const handleTabChange = (tabIndex) => {
    const view = tabIndexToView[tabIndex];
    
    if (!view) {
      console.error('Invalid tab index:', tabIndex);
      return;
    }
    
    setActiveView(view);
    
    let newData;
    switch(view) {
      case 'day':
        newData = getTodayData();
        break;
      case 'week':
        newData = getCurrentWeekData();
        break;
      case 'month':
        newData = getCurrentMonthData();
        break;
      case 'year':
        newData = getCurrentYearData();
        break;
      case 'custom':
        newData = getCustomData();
        break;
      default:
        newData =  getTodayData();
    }
    
    setChartData(newData);
  };
  
  
  
  // Modify the initial data loading
  useEffect(() => {
    if (scanHistory.length > 0) {
      const initialData = getTodayData();
      setChartData(initialData);
    }
  }, [scanHistory]);
  // Fetch QR data
  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await RestApi.getQrById(qrId);
        const history = response.data.data.qrData.scanHistory;
        setScanHistory(history);
        
        // Debug logging
        if (history.length > 0) {
          history.forEach((scan, index) => {
            const istDate = getISTDate(scan.timestamp);
          });
        }

      } catch (error) {
        console.error('Error fetching QR details:', error);
      } finally {
        setLoading(false);
      }
    };
    handleTabChange(0)
    fetchDetails();
  }, [qrId]);

  // Initialize data on mount
  // useEffect(() => {
  //   if (scanHistory.length > 0) {
  //     setChartData(getTodayData());
  //   }
  // }, [scanHistory]);

  // Update data when custom date range changes
  useEffect(() => {
    if (activeView === 'custom') {
      setChartData(getCustomData());
    }
  }, [customDateRange]);

  const processLocationData = () => {
    const locations = {};
  
    scanHistory?.forEach(scan => {
      const { city, region, country } = scan.location || {}; // Destructure location details
  
      // Format the location string (you could adjust this depending on display preference)
      const loc = city && region && country 
                  ? `${city}, ${region}, ${country}`
                  : country || 'Others';
  
      locations[loc] = (locations[loc] || 0) + 1;
    });
  
    return Object.entries(locations).map(([location, count]) => ({
      location,
      count
    }));
  };

  const processBrowserData = () => {
    const browsers = {};
    scanHistory.forEach(scan => {
      const userAgent = scan.deviceInfo;
      let browserName = 'Others';
      
      if (userAgent.includes('Chrome')) browserName = 'Chrome';
      else if (userAgent.includes('Firefox')) browserName = 'Firefox';
      else if (userAgent.includes('Safari')) browserName = 'Safari';
      else if (userAgent.includes('Edge')) browserName = 'Edge';
      
      browsers[browserName] = (browsers[browserName] || 0) + 1;
    });

    return Object.entries(browsers).map(([name, count]) => ({
      name,
      value: Math.round((count / scanHistory.length) * 100)
    }));
  };

  const processOSData = () => {
    const os = {};
    scanHistory.forEach(scan => {
      const userAgent = scan.deviceInfo;
      let osName = 'Others';
      
      if (userAgent.includes('Windows')) osName = 'Windows';
      else if (userAgent.includes('Mac')) osName = 'MacOS';
      else if (userAgent.includes('Linux')) osName = 'Linux';
      else if (userAgent.includes('Android')) osName = 'Android';
      else if (userAgent.includes('iOS')) osName = 'iOS';
      
      os[osName] = (os[osName] || 0) + 1;
    });

    return Object.entries(os).map(([name, count]) => ({
      name,
      value: Math.round((count / scanHistory.length) * 100)
    }));
  };

  const browserData = processBrowserData();
  const osData = processOSData();
  const locationData = processLocationData();
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <div className="p-6 mx-auto space-y-6 bg-slate-950 text-white">
      <h1 className="text-3xl font-bold mb-6">QR Code Details</h1>
      
      <Card className='max-w-6xl bg-slate-800 text-black'>
        <h2 className="text-xl font-semibold mb-4 text-white">Scan Statistics</h2>
        <Tabs aria-label="Time Range Tabs" onActiveTabChange={handleTabChange}>
          <Tabs.Item title="Day" >
            <div className="w-full h-[300px] text-black">
              <ResponsiveContainer width="100%" height="100%" >
                <BarChart data={chartData} className='text-black'>
                  <XAxis dataKey="time" stroke="white"/>
                  <YAxis stroke='white'/>
                  <Tooltip wrapperStyle={{ width: 100, backgroundColor: '#000000' }}/>
                  <Bar dataKey="scans" fill="#9BEC00" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Tabs.Item>
          
          <Tabs.Item title="Week" stroke="white">
            <div className="w-full h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={chartData}>
                  <XAxis dataKey="time" stroke="white" />
                  <YAxis stroke="white"/>
                  <Tooltip />
                  <Bar dataKey="scans" fill="#9BEC00" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Tabs.Item>

          <Tabs.Item title="Month">
            <div className="w-full h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={chartData}>
                  <XAxis dataKey="time" stroke="white" />
                  <YAxis stroke="white"/>
                  <Tooltip />
                  <Bar dataKey="scans" fill="#9BEC00"  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Tabs.Item>

          <Tabs.Item title="Year">
            <div className="w-full h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={chartData}>
                  <XAxis dataKey="time" stroke="white" />
                  <YAxis stroke="white"/>
                  <Tooltip />
                  <Bar dataKey="scans" fill="#9BEC00" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Tabs.Item>

          {/* <Tabs.Item title="Custom">
            <div className="w-full h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={chartData}>
                  <XAxis dataKey="time" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="scans" fill="#9BEC00" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Tabs.Item> */}
        </Tabs>
      </Card>


      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card className='bg-slate-800 text-white'>
          <h2 className="text-xl font-semibold mb-4">Operating Systems</h2>
          <div className="h-64 ">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={osData}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  {osData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="mt-4">
            {osData.map((item, index) => (
              <div key={item.name} className="flex items-center gap-2 mt-2">
                <div 
                  className="w-4 h-4 rounded"
                  style={{ backgroundColor: COLORS[index % COLORS.length] }} 
                />
                <span>{item.name}: {item.value}%</span>
              </div>
            ))}
          </div>
        </Card>

        <Card className='bg-slate-800 text-white'>
          <h2 className="text-xl font-semibold mb-4">Browsers</h2>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={browserData}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  {browserData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="mt-4">
            {browserData.map((item, index) => (
              <div key={item.name} className="flex items-center gap-2 mt-2">
                <div 
                  className="w-4 h-4 rounded"
                  style={{ backgroundColor: COLORS[index % COLORS.length] }} 
                />
                <span>{item.name}: {item.value}%</span>
              </div>
            ))}
          </div>
        </Card>

        <Card className="md:col-span-2 bg-slate-800 text-white">
          <h2 className="text-xl font-semibold mb-4">Locations</h2>
          <div className="grid gap-4">
            {locationData.map((location) => (
              <div key={location} className="flex items-center gap-4 p-4 border rounded-lg">
                <HiOutlineGlobe className="w-6 h-6 text-blue-500" />
                <div>
                  <div className="font-medium">{location.location}</div>
                  <div className="text-sm text-gray-500">{location.count} scans</div>
                </div>
              </div>
            ))}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default QRDetailsPage;