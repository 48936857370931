import React, { useState } from 'react';
import { Globe, Link, Copy, ChevronDown, Check } from 'lucide-react';

const AddSmartLinks = () => {
  const [isCopied, setIsCopied] = useState(false);
  
  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <div className="w-full min-h-screen bg-gray-900 text-gray-100 p-6">
      {/* Navigation */}
      <div className="max-w-4xl mx-auto flex items-center justify-between mb-8">
        <div className="flex items-center space-x-8">
          <div className="flex items-center space-x-4">
            <button className="px-4 py-2 bg-gray-800 rounded-lg hover:bg-gray-700 transition-colors">
              <ChevronDown size={20} />
            </button>
            <span className="text-lg font-medium">Destination</span>
          </div>
          <div className="flex items-center space-x-4">
            <span className="text-gray-400">Design</span>
          </div>
        </div>
        <button className="px-6 py-2 bg-blue-600 rounded-lg hover:bg-blue-700 transition-colors">
          Next
        </button>
      </div>

      {/* Main Content */}
      <div className="max-w-4xl mx-auto space-y-6">
        {/* Short URL Input */}
        <div className="bg-gray-800 p-6 rounded-lg">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-medium flex items-center">
              <Globe size={20} className="mr-2" />
              Short URL
            </h2>
            <ChevronDown size={20} className="text-gray-400 cursor-pointer hover:text-gray-300" />
          </div>
          <div className="flex items-center space-x-2">
            <div className="flex-1 flex items-center bg-gray-900 rounded-lg overflow-hidden">
              <select className="bg-transparent px-3 py-2 border-r border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500">
                <option>qrc.sh/</option>
              </select>
              <input
                type="text"
                value="d9Rnz"
                className="flex-1 bg-transparent px-3 py-2 focus:outline-none"
              />
            </div>
            <button 
              className="p-2 text-green-500 hover:text-green-400 transition-colors"
              onClick={handleCopy}
            >
              {isCopied ? <Check size={20} /> : <Copy size={20} />}
            </button>
          </div>
        </div>

        {/* Destination URL Input */}
        <div className="bg-gray-800 p-6 rounded-lg">
          <h2 className="text-lg font-medium flex items-center mb-4">
            <Link size={20} className="mr-2" />
            Destination URL
          </h2>
          <div className="flex items-center space-x-2">
            <input
              type="text"
              placeholder="Enter your destination URL"
              defaultValue="https://very-long-and-ugly-link.com"
              className="flex-1 bg-gray-900 px-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button className="p-2 text-gray-400 hover:text-gray-300 transition-colors">
              <Copy size={20} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSmartLinks;