import React  from 'react';
import { ArrowRight, Mail, MapPin, Phone } from 'lucide-react';
import TnC from '../../Assets/Smart_QrCodes_TnC.pdf'
import { useNavigate } from 'react-router-dom';
import logo from '../../Assets/Logo_text.png'

const Home = () => {
    const navigate=useNavigate();
  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-br from-[#002368] via-gray-900 to-[#005a62] text-white">
      <header className="p-4 flex justify-between items-center">
        <div className="text-xl font-bold ">
            <img src={logo} className='m-4 w-40'/>
        </div>
        <nav className="hidden md:flex space-x-4">
          <a href="#" className="hover:text-blue-300">Features</a>
          <a href="/doc/faq" className="hover:text-blue-300">FAQ</a>
          <a href="#" className="hover:text-blue-300">Blog</a>
          <a href="#" className="hover:text-blue-300">Affiliate</a>
        </nav>
        <button className="bg-gradient-to-r from-blue-500 to-teal-400 text-white px-4 py-2 rounded-full hover:opacity-90 transition-opacity"
        onClick={()=>navigate('/login')}
        >
          Get Started For Free
        </button>
      </header>

      <main className="flex-grow flex flex-col items-center justify-center text-center px-4">
      <h1 className="text-4xl md:text-6xl font-bold mb-4">
          Generate Dynamic and Static<br />
          QR Codes
        </h1>
        <p className="max-w-2xl mb-8">
          Harness the power of AI to create both dynamic and static QR codes. Our smart QR solutions 
          offer real-time tracking and analytics for dynamic codes, while providing reliable, 
          permanent links with static options. Elevate your engagement strategy with QR codes 
          that adapt to your needs.
        </p>
        <div className="flex flex-col md:flex-row gap-4 w-full max-w-md">
          <button className="flex-1 bg-gray-800 text-white px-6 py-3 rounded-lg hover:bg-gray-700 transition-colors flex items-center justify-center"
                onClick={()=>navigate('/login')}
                >
            <span className="mr-2">Smart QR Codes </span>
            <ArrowRight size={20} />
          </button>
          <button className="flex-1 bg-white text-gray-800 px-6 py-3 rounded-lg hover:bg-gray-100 transition-colors flex items-center justify-center">
            <span className="mr-2">Smart Link</span>
            <ArrowRight size={20} />
          </button>
        </div>
      </main>

      <footer className="bg-gray-800 text-white py-8 px-4">
        <div className="max-w-6xl mx-auto grid grid-cols-2 md:grid-cols-4 gap-8">
          <div>
            <h3 className="font-bold mb-2 text-decoration-line: underline">Smart QR Codes</h3>
            <p className="text-sm">Redefine how you use QR codes.</p>
          </div>
          <div>
            <h3 className="font-bold mb-2 text-decoration-line: underline">Legal</h3>
            <ul className="text-sm">
              <li><a href={TnC} className="hover:text-blue-300">Terms & Conditions</a></li>
              <li><a href="/doc/privacy-policy" className="hover:text-blue-300">Privacy Policy</a></li>
            </ul>
          </div>
          <div>
            <h3 className="font-bold mb-2 text-decoration-line: underline">Links</h3>
            <ul className="text-sm">
              <li><a href="#" className="hover:text-blue-300">Features</a></li>
              <li><a href="/doc/faq" className="hover:text-blue-300">FAQ</a></li>
              <li><a href="#" className="hover:text-blue-300">Blog</a></li>
              <li><a href="#" className="hover:text-blue-300">Affiliate</a></li>
              <li><a href="#" className="hover:text-blue-300">Pricing</a></li>
            </ul>
          </div>
          <div>
            <h3 className="font-bold mb-2 text-decoration-line: underline">Contact</h3>
            <ul className="text-sm">
              <li> <div className="flex items-start mb-2">
                                <MapPin className="w-5 h-5 text-[#4bff00] mr-2 mt-1 flex-shrink-0" />
                                <span className="text-sm text-white">
                                    E-1007, Prateek laurel, Sector-120, Noida
                                </span>
                                </div></li>
              <li> <div className="flex items-center mb-2">
                                <Mail className="w-5 h-5 text-[#4bff00] mr-2 flex-shrink-0" />
                                    support@smart-qrcodes.com
                                </div></li>
              <li><div className="flex items-center ">
                                <Phone className="w-5 h-5 text-[#4bff00] mr-2 flex-shrink-0" />
                                   +91 8287104351
                                </div>
                </li>
              
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;