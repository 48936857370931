import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ModalProvider } from "styled-react-modal";
import { AuthProvider, useAuth } from "./context/AuthContext";
import ProtectedRoute from "./routing/ProtectedRoute";
import App from "./App";
import './index.css';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Login from "./pages/Login/Login";
import Signup from "./pages/Signup/Signup";
import PrivacyPolicy from "./pages/Doc/PrivacyPolicy";
import FAQ from "./pages/Doc/FAQ";
import QRDetailsPage from "./components/qrComponents/QrDetails";
import ProUpgradeCard from "./pages/Premium/ProUpgradeCard";
import { EmailAuthProvider } from "firebase/auth";
import ResetPasswordRequest from "./pages/Settings/ResetPasswordRequest";
import ChangePassword from "./components/ChangePassword";
import ResetPasswordRedirect from "./pages/Settings/ResetPasswordRedirect";
import Home from "./pages/Home/Home";

const RoutingComponents = () => (
  <BrowserRouter>
    <ModalProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/doc/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/doc/faq" element={<FAQ />} />
        
        {/* Protected Routes with App Layout */}
        <Route path="/home/*" element={<ProtectedRoute element={<App />} />} />
        <Route path="/qrCode/:id" element={<ProtectedRoute element={<QRDetailsPage />} />} />
        
        {/* Other Routes */}
        <Route path="/premium" element={<ProUpgradeCard />} />
        <Route path="/api/verify-email" element={<EmailAuthProvider />} />
        <Route path="/forget-password" element={<ResetPasswordRequest />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/resetpassword" element={<ResetPasswordRedirect />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </ModalProvider>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
  </BrowserRouter>
);

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <RoutingComponents />
    </AuthProvider>
  </React.StrictMode>
);