import React, { useState, useEffect } from 'react';
import { signInWithGoogle } from '../../firebase';
import Photo from '../../Assets/Photo1.png';
import { useNavigate } from 'react-router-dom';
import { isBrowser } from 'react-device-detect';
import { publicIpv4 } from 'public-ip';
import RestApi from '../../Apis/RestApi';
import { useAuth } from '../../context/AuthContext';
import { toast } from 'react-toastify';
import termsPdf from '../../Assets/Smart_QrCodes_TnC.pdf';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const loadGoogleScript = () => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = initializeGoogleSignIn;
      document.body.appendChild(script);
    };
    const initializeGoogleSignIn = () => {
      if (window.google) {
        window.google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          callback: handleGoogleSignInResponse,
        });
        window.google.accounts.id.renderButton(
          document.getElementById('googleSignInButton'),
          { theme: 'outline', size: 'large', text: 'continue_with' }
        );
      }
    };

    loadGoogleScript();

    return () => {
      const script = document.querySelector('script[src="https://accounts.google.com/gsi/client"]');
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []);


  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      toast.error('Please fill in both email and password.');
      return;
    }

    if (!validateEmail(email)) {
      toast.error('Please enter a valid email address.');
      return;
    }

    if (!termsAccepted) {
      toast.error('Please accept the terms and conditions.');
      return;
    }

    try {
      const Address = `${await publicIpv4()}_${isBrowser ? "web" : "mobile"}`;
      let data = { email, password, address: Address };
      const response = await RestApi.signin(data);
      if (response.data.status) {
        if (response.data.msg === "Successfully logged in.") {
          localStorage.setItem("_id", response.data.data.userObj._id);
          localStorage.setItem("email", response.data.data.userObj.email);
          localStorage.setItem("type", response.data.data.type);
          localStorage.setItem("username", response.data.data.userObj.name);
          localStorage.setItem("first_login", response.data.data.userObj.first_login);
          if(response.data.data.userObj.email_verified===false){
            toast.error("Email not Verified, Please Verify your email first.")
            return 
          }
          login(response.data.data.userObj._id);
          toast.success(response.data.msg);
          const firstLogin = JSON.parse(localStorage.getItem("first_login"));

          return navigate(firstLogin === false ? '/premium' : '/home');
        } else {
          toast.error("Invalid Login Credentials");
        }
      } else {
        toast.error("Internal server Error");
      }
    } catch (error) {
      console.error("Error during login:", error);
      toast.error(error.response.data.err);
    }
  };

  const handleGoogleSignInResponse = async (response) => {
    try {
      const { credential } = response;
      const address = `${await publicIpv4()}_${isBrowser ? "web" : "mobile"}`;

      const apiResponse = await RestApi.googleSignin({ credential, address });

      if (apiResponse.data.status) {
        const { userObj, token, type } = apiResponse.data.data;
        
        // Set user data in localStorage
        setUserDataInLocalStorage(userObj, type, token);

        login(userObj._id);
        toast.success(apiResponse.data.msg);
        navigate(userObj.first_login? '/home' : '/premium');
      } else {
        toast.error(apiResponse.data.error || "Sign-in/Sign-up failed");
      }
    } catch (error) {
      console.error("Error during Google sign-in/sign-up:", error);
      toast.error("Unable to Sign In/Sign Up using Google");
    }
  };
  const setUserDataInLocalStorage = (userObj, type, token) => {
    localStorage.setItem("_id", userObj._id);
    localStorage.setItem("email", userObj.email);
    localStorage.setItem("type", type);
    localStorage.setItem("username", userObj.name);
    localStorage.setItem("first_login", userObj.first_login.toString());
    localStorage.setItem("token", token);
  };

  
  const handleTermsChange = (e) => {
    setTermsAccepted(e.target.checked);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 px-4">
      <div className="flex w-full max-w-4xl rounded-lg bg-white shadow-lg flex-col md:flex-row h-[75vh]">
        <div className="w-full md:w-1/2 p-8">
          <h2 className="text-3xl font-bold mb-4">Welcome back!</h2>

          <form className="space-y-4" onSubmit={handleFormSubmit}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm sm:leading-[2.5rem] leading-8"
              />
            </div>
            
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm sm:leading-[2.5rem] leading-8"
              />
            </div>
            <div className="flex items-center justify-between">
              {/* <div className="flex items-center">
                <input
                  id="remember_me"
                  name="remember_me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                />
                <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-900">
                  Remember me
                </label>
              </div> */}
              <div className="text-sm">
                <span onClick={() => navigate('/forget-password')} className="font-medium text-indigo-600 cursor-pointer hover:text-indigo-500">Forgot your password?</span>
              </div>
            </div>
            <div className="flex items-center">
              <input
                id="terms"
                name="terms"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                checked={termsAccepted}
                onChange={handleTermsChange}
              />
              <label htmlFor="terms" className="ml-2 block text-sm text-gray-900">
                I accept the <a href={termsPdf} target='_blank' className="text-indigo-600 cursor-pointer">terms and conditions</a> &nbsp; && <a href='/doc/privacy-policy' target='_blank' className="text-indigo-600 cursor-pointer">Privacy Policy</a>
              </label>
            </div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Sign In
            </button>
          </form>

          <div className="mt-6">
            <div id="googleSignInButton"></div>
          </div>

          <div className="mt-6 text-center">
            <p className="text-sm text-gray-600">Don't have an account? <span onClick={() => navigate('/signup')} className="font-medium text-indigo-600 hover:text-indigo-500">Sign up</span></p>
          </div>
        </div>
        <div className="w-full md:w-1/2 bg-cover bg-center hidden md:block" style={{ backgroundImage: `url(${Photo})` }}>
          <div className="flex h-full w-full justify-center items-center">
            <img src={Photo} alt="Photo" className="w-full h-full object-cover" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
